import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IndividualConfig, ToastrService } from 'ngx-toastr';
import { catchError, EMPTY, Observable, throwError } from 'rxjs';
import { GridAppsSettings } from '../../config/grid-apps.settings';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export abstract class APIBaseService {
  public url: string;

  constructor(
    public settings: GridAppsSettings,
    public http: HttpClient,
    public toastrService: ToastrService,
    private router: Router
  ) {
    this.url = settings.API_URL + this.getAPIRoot();
  }

  /**
   * Implement this for set up the root api path
   * Should return a string root path of the service
   * Example: return "/field"
   */
  public abstract getAPIRoot(): string;

  public mapRequest(
    request: Observable<any>,
    errorHandler?: (error: HttpErrorResponse) => Observable<Error>
  ): Observable<any> {
    return request.pipe(
      catchError(async (err: HttpErrorResponse) => {
        // handle 401 separately because we won't be returning an error observable
        if (err.status === 401) {
          await this.router.navigate(['/auth/login']);
          return EMPTY;
        }
        return errorHandler ? errorHandler(err) : this.handleError(err);
      })
    );
  }

  private handleError(error: HttpErrorResponse | any): Observable<Error> {
    let errMsg = 'unexpected error';
    let errTitle = 'Unknown error';

    const errConfig: Partial<IndividualConfig> = {
      closeButton: true,
      progressBar: true,
      timeOut: 10000,
    };

    if (error instanceof HttpErrorResponse) {
      if (error.status && error.status >= 500) {
        errTitle = 'Server error';
        errMsg = 'An unexpected error occurred!';
      } else if (error.message) {
        errTitle = String(error.status);
        errMsg = error.statusText;
      } else {
        errTitle = 'Network error';
        errMsg = 'Failed to connect with server';
      }

      this.toastrService.error(errMsg, errTitle, errConfig);
    } else {
      errMsg = error.message ? error.message : error.toString();
      this.toastrService.warning(errMsg, errTitle, errConfig);
    }

    return throwError(() => error);
  }
}
