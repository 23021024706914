/* All free solid fontawesome icons -> https://fontawesome.com/icons?d=gallery&s=solid&m=free */

import {
  faAngleDown,
  faAngleRight,
  faAnglesLeft,
  faAnglesRight,
  faArrowLeft,
  faBackwardStep,
  faBars,
  faBolt,
  faBookOpen,
  faChartArea,
  faChartBar,
  faChartPie,
  faChevronDown,
  faChevronUp,
  faCircle,
  faCircleExclamation,
  faCirclePlus,
  faCircleQuestion,
  faCircleXmark,
  faClipboard,
  faColumns,
  faComment,
  faCopy,
  faDownload,
  faEye,
  faFile,
  faFileCirclePlus,
  faFlag,
  faFolderTree,
  faHouse,
  faIndustry,
  faLanguage,
  faLayerGroup,
  faList,
  faListDots,
  faLocationDot,
  faLocationPin,
  faMagnifyingGlass,
  faMessage,
  faPen,
  faPenRuler,
  faPlug,
  faPlugCirclePlus,
  faPlus,
  faQuestion,
  faQuestionCircle,
  faSearch,
  faSignOut,
  faSignal,
  faSort,
  faSortDown,
  faSortUp,
  faSpinner,
  faTable,
  faTableCellsLarge,
  faTachometerAlt,
  faTag,
  faToggleOff,
  faToggleOn,
  faTrash,
  faUpload,
  faUser,
  faWaveSquare,
  faX,
  faXmark,
} from '@fortawesome/free-solid-svg-icons';

export const fontAwesomeSolidIcons = {
  faAngleDown,
  faAngleRight,
  faArrowLeft,
  faBars,
  faBookOpen,
  faChartArea,
  faChartBar,
  faChartPie,
  faChevronDown,
  faChevronUp,
  faColumns,
  faSearch,
  faTable,
  faTachometerAlt,
  faUser,
  faPlus,
  faTrash,
  faX,
  faCircleExclamation,
  faSort,
  faSortUp,
  faSortDown,
  faToggleOn,
  faToggleOff,
  faQuestionCircle,
  faCircleXmark,
  faFlag,
  faQuestion,
  faCopy,
  faCircleQuestion,
  faIndustry,
  faBolt,
  faLayerGroup,
  faWaveSquare,
  faSignal,
  faCircle,
  faMessage,
  faLanguage,
  faSignOut,
  faHouse,
  faClipboard,
  faAnglesLeft,
  faAnglesRight,
  faTableCellsLarge,
  faList,
  faLocationPin,
  faLocationDot,
  faPlug,
  faPlugCirclePlus,
  faTag,
  faFile,
  faDownload,
  faFileCirclePlus,
  faUpload,
  faBackwardStep,
  faSpinner,
  faComment,
  faPenRuler,
  faFolderTree,
  faEye,
  faCirclePlus,
  faPen,
  faListDots,
  faMagnifyingGlass,
  faXmark,
};
