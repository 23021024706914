import { NgModule } from '@angular/core';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { GridAppsRoutingModule } from './grid-apps-routing.module';

@NgModule({
  declarations: [],
  imports: [GridAppsRoutingModule, NgbModule],
  providers: [],
  bootstrap: [],
  exports: [GridAppsRoutingModule],
})
export class GridAppsModule {}
