import { Injectable } from '@angular/core';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { BehaviorSubject, map, Observable, of, shareReplay, switchMap, tap } from 'rxjs';
import { GridAppsSettings } from '../../core/config/grid-apps.settings';
import { IAuthenticatedUser } from './interfaces/user.interface';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private user$: BehaviorSubject<IAuthenticatedUser | null> = new BehaviorSubject<IAuthenticatedUser | null>(null);
  private userObservable$: Observable<IAuthenticatedUser | null> = this.user$.pipe(shareReplay(1));
  private appInsights: ApplicationInsights;

  constructor(
    private oidcSecurityService: OidcSecurityService,
    private userService: UserService,
    private settings: GridAppsSettings
  ) {
    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: this.settings.APP_INSIGHTS_INSTRUMENTATION_KEY,
      },
    });
    this.appInsights.loadAppInsights();
  }
  private checkAuth$ = this.oidcSecurityService.checkAuth().pipe(
    switchMap((result) => {
      if (result.isAuthenticated) {
        return this.userService.getDetails().pipe(
          map((user) => {
            // Add the department to roles so that permission checking is easier
            const authenticatedUser = {
              ...user,
              roles: [...user.roles],
            };

            // Set authenticated user context in Application Insights
            this.appInsights.setAuthenticatedUserContext(user.email);
            this.appInsights.trackEvent({
              name: 'User',
              properties: {
                email: user.email,
                department: user.department,
              },
            });

            // Update the user BehaviorSubject
            this.user$.next(authenticatedUser);

            return authenticatedUser;
          })
        );
      }
      return of(null);
    }),
    tap((user) => this.user$.next(user)),
    // check auth only once if we use this observable multiple times
    shareReplay(1)
  );

  get userData$(): Observable<IAuthenticatedUser | null> {
    return this.userObservable$;
  }

  get user(): IAuthenticatedUser | null {
    return this.user$.value;
  }

  checkAuth(): Observable<IAuthenticatedUser | null> {
    return this.checkAuth$;
  }

  public hasAllRoles(...roles: string[]): Observable<boolean> {
    return this.userObservable$.pipe(
      map((user) => {
        if (!user) {
          return false;
        }
        return roles.every((role) => user.roles.includes(role));
      })
    );
  }

  public hasAtleastOneRole(...roles: string[]): Observable<boolean> {
    return this.userObservable$.pipe(
      map((user) => {
        if (!user) {
          return false;
        }
        return roles.some((role) => user.roles.includes(role));
      })
    );
  }

  public logout() {
    this.user$.next(null);
    this.oidcSecurityService.logoffAndRevokeTokens().subscribe((result) => console.log(result));
  }
}
