import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GridAppsSettings } from './core/config/grid-apps.settings';

@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>',
})
export class AppComponent {
  // private subscriptions = new Subscription();

  constructor(private translate: TranslateService, private settings: GridAppsSettings) {
    translate.setDefaultLang(settings.DEFAULT_LANGUAGE);
    translate.use(settings.DEFAULT_LANGUAGE);
  }
}
