/* All free solid fontawesome icons -> https://fontawesome.com/icons?d=gallery&s=regular&m=free */

import {
  faBell,
  faCircleCheck,
  faCircleQuestion,
  faCircleXmark,
  faCopyright,
  faEye,
  faFile,
} from '@fortawesome/free-regular-svg-icons';

export const fontAwesomeRegularIcons = {
  faBell,
  faCircleQuestion,
  faCopyright,
  faEye,
  faFile,
  faCircleCheck,
  faCircleXmark,
};
