import { IAuthenticatedUser } from './interfaces/user.interface';
import { inject } from '@angular/core';
import { AuthService } from './auth.service';
import { Observable, take } from 'rxjs';
import { filter } from 'rxjs/operators';

function userResolver(): Observable<IAuthenticatedUser> {
  return inject(AuthService).userData$.pipe(
    filter((user): user is NonNullable<IAuthenticatedUser> => user != null),
    take(1)
  );
}

export default userResolver;
