import { Component, OnInit } from '@angular/core';
import { CommonModule, Location } from '@angular/common';
import { AuthService } from '../../auth.service';

@Component({
  selector: 'grid-apps-login',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  constructor(private authService: AuthService, private location: Location) {}

  ngOnInit(): void {
    this.authService.logout();
    this.location.historyGo(-2);
  }
}
