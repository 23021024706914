import { NgModule } from '@angular/core';
import { Route, RouterModule, Routes } from '@angular/router';
import { AutoLoginPartialRoutesGuard } from 'angular-auth-oidc-client';
import userResolver from '../shared/auth/auth.resolver';
import { MODULE_ROUTE, moduleRouterFactory } from '../shared/injection/module-route';

const protectedRoutes: Route = {
  path: '',
  canActivate: [AutoLoginPartialRoutesGuard,],
  resolve: {
    user: userResolver,
  },
  children: [
    {
      path: '',
      providers: [
        {
          provide: MODULE_ROUTE,
          useValue: moduleRouterFactory('/'),
        },
      ],
      loadChildren: () => import('./doc-numbering/doc-numbering.module').then((m) => m.DocNumberingModule),
    }
  ],
};

const unprotectedRoutes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('../shared/auth/auth-routing.module').then((m) => m.AuthRoutingModule),
  },
];

const routes: Routes = [protectedRoutes, ...unprotectedRoutes];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class GridAppsRoutingModule { }