import { Injectable } from '@angular/core';
import { version } from '../../../assets/version';
import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class GridAppsSettings {
  // private readonly API_BASE_URL: string = 'api/';
  // public readonly API_VERSION: string = 'v1';

  // private static sharedInstance: GridAppsSettings = new GridAppsSettings();

  // public static getInstance(): GridAppsSettings {
  //   return GridAppsSettings.sharedInstance;
  // }

  // constructor() {
  //   if (GridAppsSettings.sharedInstance) {
  //     throw new Error('Error: Use GridAppsSettings.sharedInstance <singleton>');
  //   }
  //   GridAppsSettings.sharedInstance = this;
  // }

  public get API_URL(): string {
    return environment.apiUrl;
  }

  public get SECURED_ROUTES(): string[] {
    return environment.securedRoutes;
  }

  public get AZ_TENANT(): string {
    return environment.azTenant;
  }

  public get AZ_BE_CLIENT_ID(): string {
    return environment.azBEClientId;
  }

  public get APP_INSIGHTS_INSTRUMENTATION_KEY(): string {
    return environment.instrumentationKey;
  }

  public get DEFAULT_LANGUAGE(): string {
    return environment.defaultLanguage;
  }

  public get VERSION(): string {
    return version;
  }
}
