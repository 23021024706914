import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'grid-apps-auth-callback',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './callback.component.html',
  styleUrls: ['./callback.component.scss'],
})
export class CallbackComponent {}
