import { Injectable } from '@angular/core';
import { APIBaseService } from '../../core/services/api-services/api-base.service';
import { IAuthenticatedUser } from './interfaces/user.interface';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserService extends APIBaseService {
  getAPIRoot(): string {
    return '/auth';
  }

  getDetails(): Observable<IAuthenticatedUser> {
    return this.mapRequest(this.http.get<IAuthenticatedUser>(this.url + '/user'));
  }
}
