<div class='container-fluid'>
  <div class='row vh-100 vw-100 d-flex flex-row align-content-center'>
    <div class='col-12'>
      <div class='text-center'>
        <p>
          {{ 'auth.unauthorized' | translate }}
        </p>
        <button class='btn btn-primary' (click)='goBack()'>
          <fa-icon [icon]="['fas', 'backward-step']"></fa-icon>
          {{ 'auth.back' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
