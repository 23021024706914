import { InjectionToken } from '@angular/core';

export type ModuleRouter = (...args: (string | number)[]) => (string | number)[];
export type ModuleRouterFactory = (moduleRoute: string) => ModuleRouter;

export const MODULE_ROUTE = new InjectionToken<ModuleRouter>('Module Relative Route');
export const moduleRouterFactory: ModuleRouterFactory = (moduleRoute: string) => {
  return (...args: (string | number)[]) => {
    return [moduleRoute, ...args];
  };
};
