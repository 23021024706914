import { Component, inject } from '@angular/core';
import { CommonModule, Location } from '@angular/common';
import { IconsModule } from '../../../icons/icons.module';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-unauthorized',
  standalone: true,
  imports: [CommonModule, IconsModule, TranslateModule],
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.scss'],
})
export class UnauthorizedComponent {
  private location: Location = inject(Location);

  goBack() {
    this.location.back();
  }
}
